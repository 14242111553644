import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import Layout from "../components/layout";
import Seo from "../components/seo";

// markup
const ContactPage = () => {
  const data = useStaticQuery(graphql`
    query ContactQuery {
      sanityContactPage {
        email
        description
        image {
          asset {
            gatsbyImageData(width: 1300)
          }
        }
      }
    }
  `);
  const image1 = getImage(data.sanityContactPage.image.asset);
  return (
    <Layout>
      <Seo title="Contact Us" />
      <div className="md:grid md:grid-cols-2 md:grid-flow-row-dense">
        <section className={`p-20 flex flex-col justify-center`}>
          <h2 className="text-3xl leading-6 font-medium text-gray-50">
            Contact Us
          </h2>
          <p className="mt-4 text-base text-gray-50">
            {data.sanityContactPage.description}
          </p>
          <p className="flex mt-4 text-base text-gray-50">
            <svg
              className="flex-shrink-0 mr-1.5 h-10 w-10"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span className="self-center">{data.sanityContactPage.email}</span>
          </p>
        </section>
        <GatsbyImage className="h-screen" image={image1} />
      </div>
    </Layout>
  );
};

export default ContactPage;
